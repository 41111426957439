<template>
  <div class="content">
    <div class="m-card">
      <div>
        <div class="m-whitebox" v-if="!bindPhone">
          <Row :gutter="8" class="list-border">
            <i-col span="4">手机号</i-col>
          </Row>
          <div class="account-box">
            <p>原手机号：{{ phone }}</p>
            <div class="service-code">
              <Input
                v-model="numCode"
                type="text"
                placeholder="请输入验证码"
                style="width: 290px"
              />
              <Button
                type="text"
                v-if="showCode"
                @click="getCaptcha"
                class="code-btn1"
                >发送验证码</Button
              >
              <Button
                type="text"
                v-else
                disabled
                class="code-btn2"
                style="color: #ccc"
                >{{ count }}s后重新获取</Button
              >
            </div>

            <div class="button-box">
              <Button @click="next()" type="primary" :loading="loading"
                >下一步</Button
              >
              <Button @click="resetInit()">取消</Button>
            </div>
          </div>
        </div>

        <div class="m-whitebox" v-else>
          <Row :gutter="8" class="list-border">
            <i-col span="4">手机号</i-col>
          </Row>
          <div class="account-box">
            <div style="margin-bottom: 1em">
              <Input
                v-model="newPhone"
                type="text"
                placeholder="输入新手机号"
                style="width: 290px"
              />
            </div>

            <div class="service-code">
              <Input
                v-model="numCode"
                type="text"
                placeholder="请输入验证码"
                style="width: 290px"
              />
              <Button
                type="text"
                v-if="showCode"
                @click="getCaptcha2"
                class="code-btn1"
                >发送验证码</Button
              >
              <Button
                type="text"
                v-else
                disabled
                class="code-btn2"
                style="color: #ccc"
                >{{ count }}s后重新获取</Button
              >
            </div>
            <div class="button-box">
              <Button @click="send()" type="primary" :loading="loading"
                >保存</Button
              >
              <Button @click="resetInit()">取消</Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--        <template v-else>-->
    <!--            <div class="m-whitebox">-->
    <!--                <div class="account-box">-->
    <!--                    <div style="margin-bottom: 1em;">-->
    <!--                        <Input v-model="newPhone" type="text" placeholder="输入手机号" style="width: 290px" />-->
    <!--                    </div>-->

    <!--                    <div class="service-code">-->
    <!--                        <Input v-model="numCode" type="text" placeholder="请输入验证码" style="width: 290px" />-->
    <!--                        <Button type="text" v-if="showCode" @click="getCaptcha2" class="code-btn1">发送验证码</Button>-->
    <!--                        <Button-->
    <!--                                type="text"-->
    <!--                                v-else-->
    <!--                                disabled-->
    <!--                                class="code-btn2"-->
    <!--                                style="color:#ccc;"-->
    <!--                        >{{count}}s后重新获取</Button>-->
    <!--                    </div>-->
    <!--                    <div class="button-box">-->
    <!--                        <Button @click="send()" type="primary" :loading="loading">保存</Button>-->
    <!--                        <Button @click="resetInit()">取消</Button>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </template>-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "bind",
  data() {
    return {
      loading: false,
      bindPhone: false, // 绑定新手机号码
      phone: "",
      numCode: "", // 验证码
      msgStr: "发送验证码",
      onOff: true,
      newPhone: "",
      link: "",
      openMsg: true,

      timer: null,
      showCode: true,
      count: "",
      codeKey: "",
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      uploadUrl: "getUploadUrl",
      formHeaders: "getFormHeaders",
      userInfo: "getUser",
    }),
  },
  created() {
    if (!(this.userInfo && this.userInfo.phoneConfirm)) {
      this.bindPhone = true;
    }
    this.phone = this.userInfo.phone;
  },
  methods: {
    ...mapActions({
      validatePhone: "loginModule/validatePhone",
      getMsgCode: "getMsgCode",
      validateCode: "loginModule/validateCode",
      patchUser: "user/patchUser",
    }),
    async next() {
      const bool = await this.validMsgCode();
      console.log("bool:", bool);
      if (bool) {
        this.reset();
        this.bindPhone = true;
      }
    },
    resetInit() {
      this.$router.push(
        `/user/password/user?exhibitionId=${this.exhibitionId}`
      );
    },
    async getCaptcha2() {
      if (!this.newPhone) {
        let txt = "";
        if (!txt) {
          txt = "请输入手机号码";
        } else if (/^[1][0-9]{10}$/.test(this.newPhone)) {
          txt = "";
        } else {
          txt = "请输入正确的手机号码";
        }
        if (txt) {
          this.$Message.error({ content: txt, duration: 2 });
        }
        return;
      }
      let res = await this.validatePhone(this.newPhone);
      if (res && res.data) {
        this.$Message.error({
          content: `手机号（${this.newPhone}）已绑定其他账号`,
          duration: 2,
        });
        return;
      } else {
        this.downTime2();
      }
    },
    async getCaptcha() {
      this.downTime();
    },
    async downTime2() {
      const valid = await this.getMsgCode(this.newPhone);
      if (valid && valid.result) {
        this.codeKey = valid.data.key;
      }
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.showCode = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.showCode = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    async downTime() {
      const valid = await this.getMsgCode(this.phone);
      if (valid && valid.result) {
        this.codeKey = valid.data.key;
      }
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.showCode = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.showCode = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    async validMsgCode() {
      // 验证code
      if (!this.numCode) return false;
      if (!this.codeKey) {
        this.$Message.warning("请获取验证码!");
        return false;
      }
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 5000);

      let valid = await this.validateCode({
        key: this.codeKey,
        code: this.numCode,
      });
      if (!valid) {
        this.$Message.error("验证未通过!");
        return false;
      }
      return true;
    },
    async send() {
      // 验证code
      if (!this.numCode) return false;
      if (!this.codeKey) {
        this.$Message.warning("请获取验证码!");
        return false;
      }
      let valid = await this.validateCode({
        key: this.codeKey,
        code: this.numCode,
      });
      if (!valid) {
        this.$Message.error("验证未通过!");
        return false;
      }

      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 5000);
      const req = {
        id: this.userInfo.inMember.memberId,
        phone: this.newPhone,
      };
      let bool = await this.patchUser(req).catch((err) => {
        this.loading = false;
      });
      if (bool.result) {
        this.$Message.success("绑定成功！");
        this.$router.push(
          `/user/password/user?exhibitionId=${this.exhibitionId}`
        );
      } else {
        this.$Message.error(bool.message);
      }
      this.loading = false;
    },
    reset() {
      this.msgStr = "发送验证码";
      this.onOff = !this.onOff;
      this.bindPhone = false;
      this.loading = false;
      this.showCode = true;
      this.phone = "";
      this.numCode = "";
      this.onOff = true;
      this.newPhone = "";
      this.link = "";
      this.openMsg = true;
      this.count = "";
      this.codeKey = "";
      clearInterval(this.timer);
      this.timer = null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.content {
  min-height: 700px;
  .m-card {
    border: solid 1px #ededed;

    .mc-top-title::before {
      opacity: 0;
      font-size: 16px;
    }
    .m-whitebox {
      min-width: 900px;
      background: #fff;
      min-height: 500px;
      margin: 24px;
    }
    .list-border {
      color: #999;
      line-height: 40px;
      border-bottom: 1px solid rgb(221, 221, 221);
      padding: 10px 0;
    }
    .account-box {
      padding-top: 20px;
      padding-left: 60px;
      width: 330px;
      & > p {
        margin-bottom: 1em;
        text-indent: 4px;
      }
    }
    .button-box {
      margin-top: 20px;
      button {
        width: 74px;
        margin-right: 5px;
      }
    }
    .service-code {
      position: relative;
      .code-btn1 {
        position: absolute;
        top: 2px;
        right: -10px;
        height: 28px;
        padding: 0;
        @include font_color(#1890ff);
      }
      .code-btn2 {
        position: absolute;
        top: 2px;
        right: -10px;
        height: 28px;
        padding: 0;
      }
    }
  }
}
</style>
